<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <b-card class="mb-10" v-if="authStatus === 'logout'"
        >• ログアウトしました</b-card
      >
      <!-- Login v1 -->
      <b-card class="mb-0">
        <h2 class="brand-text">ログイン</h2>

        <!----------------------------------------------- FORM -------------------------------------------------------->
        <validation-observer ref="loginForm">
          <b-form class="auth-login-form mt-2" @submit.prevent="login">
            <!-- field: email -->
            <validation-provider
              #default="{ errors }"
              name="メールアドレス"
              vid="login_id"
            >
              <b-form-group label-for="id" label="メールアドレス">
                <b-form-input
                  id="id"
                  class="form-control-merge no-icon"
                  v-model="form.login_id"
                  :state="errors.length > 0 ? false : null"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- field: password -->
            <validation-provider
              #default="{ errors }"
              name="パスワード"
              vid="password"
            >
              <b-form-group label-for="password" label="パスワード">
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="password"
                    v-model="form.password"
                    class="form-control-merge no-icon"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false : null"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- link -> reset password page -->
            <div class="link-login" @click="setAuthStatus">
              <router-link :to="{ name: 'send-mail-reset' }"
                >パスワードを忘れた方はこちら</router-link
              >
            </div>

            <!-- submit button -->
            <div class="btn-login">
              <b-button variant="primary" type="submit" :disabled="invalid">
                ログイン
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-card>
    </div>
  </div>
</template>

<script>
import { apiService } from "@/services/api.service";
import JwtService from "@/services/jwt.service";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import {
  BButton,
  BCard,
  BCardText,
  BCardTitle,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BLink,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { STATUS } from "@/constant/constant";

export default {
  components: {
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      form: {
        login_id: "",
        password: "",
      },
      authStatus: "",
    };
  },
  mounted() {
    this.authStatus = this.$store.state.app.authStatus;
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeOffIcon" : "EyeIcon";
    },
  },
  methods: {
    setAuthStatus() {
      this.$store.dispatch("app/setAuthStatus", "");
    },

    async login() {
      try {
        const response = await apiService.post("auth/login", this.form);
        this.endLoading();
        if (response.data.code === STATUS.SUCCESS) {
          JwtService.saveToken(response.data.data.access_token);
          localStorage.setItem(
            "user",
            JSON.stringify(response.data.data.admin)
          );
          await this.$store.commit(
            "app/SET_ADMIN_INFO",
            response.data.data.admin
          );
          this.$router.push({ name: "dashboard" });
        } else if (response.data.code === STATUS.BAD_REQUEST) {
          this.$refs.loginForm.setErrors(response.data.data);
        } else {
          this.$refs.loginForm.setErrors({
            login_id: ["メールアドレスまたはパスワードが間違っています。"],
          });
        }
      } catch (error) {
        this.endLoading();
        let errorNotification = JSON.parse(error.data.data);
        if (errorNotification.content && errorNotification.title) {
          this.notifyError(errorNotification.content, errorNotification.title);
        } else {
          this.notifyError("SERVER_ERROR");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/pages/page-auth.scss";
@import "@core/scss/core.scss";
@import "@/assets/scss/style.scss";

.brand-text {
  font-family: "Noto Sans", sans-serif;
  font-size: 25px;
  font-weight: 700;
  line-height: 44px;
}

.link-login {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;

  a {
    text-decoration: none;
  }
}

.btn-login {
  display: flex;
  justify-content: center;

  button {
    width: 170px;
  }
}
</style>
